<template>
  <div id="rodape">
    <div v-if="!carregando" class="container-fluid">
      <div class="row">
        <div class="col-lg-4 mt-5">
          <h3>{{ $t("Ligue para:") }}</h3>
          <h2>{{ $t("0800 600 3130") }}</h2>
          <h3 class="my-4">{{ $t("ou") }}</h3>
          <NuxtLink
            class="botao botao-laranja py-3"
            :to="localePath('clinicas', locale)"
          >
            {{ $t("Agendar avaliação") }}
          </NuxtLink>
        </div>

        <div class="col-lg-4 mt-5">
          <ul>
            <li
              v-for="menu of menusRef"
              v-bind:key="menu.id"
              :hidden="!menu.mostra"
            >
              <NuxtLink
                v-if="menu.mostra"
                class="menu-item"
                :to="localePath(menu.link, locale)"
              >
                {{ $t(menu.texto) }}
              </NuxtLink>
            </li>
          </ul>
          <a v-if="$isMobile()">Mobile</a>
          <a v-if="$isTablet()">Tablet</a>
          <a v-if="$isDesktop()">Desktop</a>
        </div>

        <div class="col-lg-4 mt-5">
          <CustomPicture
            width="200"
            height="65"
            class="mb-5"
            :imagem="`logo-sorrifacil-branco-${locale}`"
          />

          <div>
            <NuxtLink
              v-for="rede in locale == 'es' ? redes_es : redes_pt"
              :key="rede.icone"
              :to="rede.url"
              target="_blank"
            >
              <img
                :src="'/' + rede.icone + '.svg'"
                :alt="rede.icone"
                :aria-label="rede.icone"
                class="rede float-center"
                @click="abrirLink(url)"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { t, locale } = useI18n();
const localePath = useLocalePath();

const { data: menus } = await useFetch("/api/site/menu", {
  headers: { "Content-Language": locale },
});
const carregando = ref(true);
const menusRef = ref(menus);

const redes_pt = [
  {
    icone: "instagram",
    url: "https://www.instagram.com/sorrifacil/",
  },
  {
    icone: "facebook",
    url: "https://www.facebook.com/Clinicas.Sorrifacil",
  },
  {
    icone: "linkedin",
    url: "https://www.linkedin.com/company/clinicas-sorrif-cil/",
  },
  {
    icone: "youtube",
    url: "https://www.youtube.com/@Sorrifacil",
  },
];

const redes_es = [
  {
    icone: "instagram",
    url: "https://www.instagram.com/sorrifacil_uruguay/",
  },
  {
    icone: "facebook",
    url: "https://www.facebook.com/sorrifaciluruguay/",
  },
  {
    icone: "linkedin",
    url: "https://www.linkedin.com/company/clinicas-sorrif-cil/",
  },
  {
    icone: "youtube",
    url: "https://www.youtube.com/@Sorrifacil",
  },
];

onMounted(() => {
  menusRef.value.push({
    texto: t("Política de Privacidade"),
    link: t("politica-de-privacidade"),
  });

  carregando.value = false;
});
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Rodape",
};
</script>
